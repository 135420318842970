@font-face { font-family: 'Orbitron', sans-serif !important;
    src: url('https://fonts.cdnfonts.com/css/futura-pt?styles=117663,117667,117668,117664,117666,117662,117665') }

    @import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;600&display=swap');
   

    .modal {
      
      font-size: 12px;
	    background-position: center center;
      background-repeat: no-repeat;
      max-width: 100% !important;
      background-size: cover;
      border-radius: 30px;
      font-family: 'Orbitron', sans-serif !important;
      
      
     
    }
    .blue{
      color:rgb(0 213 255 / 80%) !important;

    }

    .css-lg2zl1 {
      color: rgb(0 213 255 / 80%) !important;
    }
    .css-1c02ksv{
      font-size: 13px !important;
    }
    .css-1tccf60 {
      font-size: 13px !important;
    }

    .css-1tffnd6 {
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
      max-width: 360px !important;
      margin: 0 auto; /* Center align the grid within its parent */
      
      align-items: center; /* Center align items vertically */
  }

    .chakra-ui-light{
      background-size: cover;
    }
    .popup-content {
      background: #242831 !important;
      width: 85%;
      margin: auto;
      border-radius: 30px;
  
   
  }
  
  /* Apply the 40% max width for screens wider than a certain breakpoint */
  @media (min-width: 768px) {
      .popup-content {
          max-width: 40%;
          
          
      }
  }
    

    .popup-content::after{
      overflow-y: scroll !important;
      
    }

    .form-control{
      border-radius: 30px;  
      font-size: 15px;
      background: #393939b8 !important;
      width: 70%;
      padding: 7px 5px;
      margin: auto;
      text-align: center;
      font-weight: 700;
      color: #ffffffc4;
      font-family: 'Orbitron', sans-serif !important;
      border-color: #d5d5d5 ;
      border: 2px !important;
    }

    
    
    .modal > .content {
      width: 100%;
      padding: 10px 5px;
      color:#cfcece;
      font-weight: bold;
      border:none !important;
      font-family: 'Orbitron', sans-serif !important;
      border-color: #d5d5d5 !important;
      
    }
    
    .modal > .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
    }
    .css-1gx57aq{
      background: #00000000 !important;
      color: #ffffff !important;
    }
    .spacer{
      padding-top: 10px;
    }


    .modal > .headerMD {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
      font-size: 20px;
      font-weight: bolder;
      color: #cdcdcd;
    }
    .ju367vex{
      color: rgb(255 255 255 / 1) !important;
    }

    .modal > .inform {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
      font-size: 15px;
      font-weight: bolder;
      color: #0ff;
    }

  
    .css-jv498s{
      display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    --card-bg: none !important;
    background-color: var(--card-bg);
    color: var(--chakra-colors-chakra-body-text);
    border-radius: var(--chakra-radii-md);
    --card-padding: var(--chakra-space-5);
    box-shadow: var(--chakra-shadows-base);
    }

h1,h2,h3{
  font-family: 'Orbitron', sans-serif !important;
}



/**
  * Meetup
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  @keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  
  .meetup {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    height: 10px;
    width: 10px; }
    .meetup div {
      height: 100%;
      position: absolute;
      width: 100%;
      -webkit-animation: meetup 1.25s infinite ease;
              animation: meetup 1.25s infinite ease;
      background: rgb(7, 7, 7);
      border-radius: 100%; }
      .meetup div:nth-child(1) {
        --rotation: 90; }
      .meetup div:nth-child(2) {
        --rotation: 180; }
      .meetup div:nth-child(3) {
        --rotation: 270; }
      .meetup div:nth-child(4) {
        --rotation: 360; }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }





              body {
                margin: auto;
                font-family: 'Orbitron', sans-serif !important;
                overflow: auto;
                background: linear-gradient(315deg, rgb(19, 19, 19) 3%, rgba(21, 0, 103, 0.863) 38%, rgba(0, 16, 104, 0.842) 68%, rgba(0, 0, 0, 0.795) 98%);
               
                background-size: 100% 100%;
                background-attachment: fixed;
            }
            
            @keyframes gradient {
                0% {
                    background-position: 0% 0%;
                }
                50% {
                    background-position: 100% 100%;
                }
                100% {
                    background-position: 0% 0%;
                }
            }
            
            .wave {
                background-color: linear(45deg, #0017e7, #060026);
                border-radius: 1000% 1000% 0 0;
                position: fixed;
                width: 200%;
                height: 12em;
                animation: wave 10s -3s linear infinite;
                transform: translate3d(0, 0, 0);
                opacity: 0.8;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
            
            .wave:nth-of-type(2) {
                bottom: -1.25em;
                animation: wave 18s linear reverse infinite;
                opacity: 0.8;
                color:rgb(0, 26, 199)
            }
            
            .wave:nth-of-type(3) {
                bottom: -2.5em;
                animation: wave 20s -1s reverse infinite;
                opacity: 0.9;
                color:rgb(0, 14, 29)
            }
            
            @keyframes wave {
                2% {
                    transform: translateX(1);
                }
            
                25% {
                    transform: translateX(-25%);
                }
            
                50% {
                    transform: translateX(-50%);
                }
            
                75% {
                    transform: translateX(-25%);
                }
            
                100% {
                    transform: translateX(1);
                }
            }
            .colored-toast.swal2-icon-success {
              background-color: #296f00 !important;
            }
            
            .colored-toast.swal2-icon-error {
              background-color: #6c0000 !important;
            }
            
            .colored-toast.swal2-icon-warning {
              background-color: #783800 !important;
            }
            
            .colored-toast.swal2-icon-info {
              background-color: #005574 !important;
            }
            
            .colored-toast.swal2-icon-question {
              background-color: #00726a !important;
            }
            
            .colored-toast .swal2-title {
              color: rgb(101, 101, 101);
            }
            
            .colored-toast .swal2-close {
              color: rgb(47, 47, 47);
            }
            
            .colored-toast .swal2-html-container {
              color: rgb(27, 27, 27);
            }

            .popup-overlay {
              background: rgb(3 17 20 / 40%);
              overflow: scroll !important;
            }

            .swal2-html-container, .swal2-title{
             color: #ffffff;
             font-size: 20px !important;
              font-size: medium;
              font-family: 'Orbitron', sans-serif !important;
            }

            .coldrsp{
              font-family: 'Orbitron', sans-serif !important;
              font-size: medium;
            }
            .swal2-fire, .swal2-title{
              text-transform: uppercase;
              

  background-image: linear-gradient(
    -225deg,
    #ffffff 0%,
    #ffffff 29%,
    #ffffff 67%,
    #ffffff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 30px;;
            }
            .swal2-border-radius { -webkit-border-radius: 40 !important; -moz-border-radius: 40 !important; border-radius: 40 !important; }
.lgl{
  
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 20px;
  border: 2px !important;
  border-color: #00ffe5 !important;



 
}
.css-1sgs4n2{background: #000000 !important;}


.lglimg{
  
  animation: rotateY 6s infinite;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 5px;


  }


/* animation */
@-webkit-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }}

@-webkit-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}

}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
}
25% {
    transform: rotateY(0deg);
}
75% {
    transform: rotateY(360deg);
}
100%{
    transform: rotateY(360deg);
}
}



.sideimg{
  animation: rotateY 5s infinite;
  border-radius: 30px;
  border: 5px solid #555;
  border-color: #00ffea;
  

  box-shadow:
  inset 0 0 20px rgb(0, 30, 79),      /* inner white */
  inset 20px 0 30px rgb(0, 30, 79),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 30, 79),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 30, 79),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 30, 79), /* inner right cyan broad */
  0 0 1px rgb(255, 255, 255),            /* outer white */
  -10px 0 10px rgb(173, 190, 255),        /* outer left magenta */
  10px 0 10px rgb(245, 252, 255);         /* outer right cyan */
}

.cardbody{
  color: #cfcece !important;
  
}
.bnbpool{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.css-433779{
  color: #cfcece !important;
  font-size: 20px !important;
}




.css-8pbt74{
  background: #00000000 !important;
}
.css-iquc7e{
  border-color: rgb(77, 77, 77) !important;;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 1px rgba(0, 94, 255, 0.724),            /* outer white */
  -10px 0 10px rgb(0, 145, 255),        /* outer left magenta */
  10px 0 10px rgb(0, 162, 255);      /* outer right cyan */
  display: flex !important;
  border-width: 2px;
  border-radius: 30px !important;
  
}


.linebtm{
  
  border-color:rgb(57 57 255) !important;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 1px rgba(0, 94, 255, 0.724),            /* outer white */
  -10px 0 10px rgb(0, 69, 122),        /* outer left magenta */
  10px 0 10px rgb(0, 8, 255);      /* outer right cyan */
  display: flex !important;
  border-width: 2px;
  border-radius: 30px !important;
  

}


.chakra-card__footer{
background: transparent !important;
}

.css-1trxw3w{
  background: transparent !important;
}
.css-og0ais{
  background: transparent !important;
  border-bottom-left-radius: 30px;
  border-radius: 30px !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */
}

.css-cbyey{
  background: transparent !important;
}

 
.css-19c8aov{
  grid-gap: 0px !important;
  
  background-color: transparent !important; 


  color: #0ff;
  border-radius: 30px;
  border-color: #ffffff;
  box-shadow :
  inset 0 0 20px rgb(0, 30, 79),      /* inner white */
  inset 20px 0 30px rgb(0, 30, 79),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 30, 79),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 30, 79),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 30, 79), /* inner right cyan broad */
  0 0 1px rgb(255, 255, 255),            /* outer white */
  -10px 0 10px rgb(173, 190, 255),        /* outer left magenta */
  10px 0 10px rgb(245, 252, 255);         /* outer right cyan */

 

}

  
.pompool{

  background-image: linear-gradient(
    -180deg,
    #80b3ff 0%,
    #80b3ff 29%,
    #80b3ff 67%,
    #80b3ff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 30px;
}



.pompool1{

  background-image: linear-gradient(
    -180deg,
    #c3c3c3 0%,
    #c2c2c2 29%,
    #c3c3c3 67%,
    #c1c1c1 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 30px;
}



@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.css-2l2wev{
  
  border-color: rgb(77, 77, 77) !important;;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */
  display: flex !important;
  
  
}

.css-11a8r05{
  
  border-color: rgb(77, 77, 77) !important;;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */
  display: flex !important;
  
  
}

.css-1dhyq3r{
  display:flex !important;
  min-width: 300px;
}

.css-1tccf60{
  color: #cfcece;
}

.css-1sl53ol{
  color: #cfcece;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.cardtxt{
  color: #cfcece;
  font-family: 'Orbitron', sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.headertxt{

  color: #ffffff !important;
  font-size: 25px !important;
}

.txtinbar{
  
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #005177 0%,
    #0061d8 29%,
    #0089d3 67%,
    #00ffe5 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 23px;
}


.btntxt{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 18px;

}

.swal2-popup{
  background: #a6a6a63b !important;
}

.btnlabel{
  font-size: 20px !important;
  text-transform: uppercase;


  color: rgb(0 213 255 / 80%);


  
  
  font-weight:900;

}

.btnsubmit{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #ffffff;
  display: inline-block !important;
  font-weight: 450 !important;
 
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;

}

.btnsubmit2{
  text-transform: uppercase;
  margin-top: px;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #fff;
  display: inline-block !important;
  font-weight: 450 !important;
 
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;

}

.css-12anu69{
  border-radius: 30px !important;
}







.btnsubmit1{
text-transform: uppercase;

  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #fff;
  font-weight: 450 !important;
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;

}


.element.style{
  position: none !important;
}

.css-1uqpm9{
  font-size: 13px !important;
  font-weight:bolder !important;
  border-radius: 30px !important;
  
  

}

.ju367v11{
  font-family: 'Orbitron', sans-serif !important;
}

.xkgoko{
  background-size: cover;
}

.stakebox{
  border: 2px;
  background-color: #41414135;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */


}



































@-webkit-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-moz-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-o-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-moz-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-o-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-webkit-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@-moz-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@-o-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
html {
  font-size: 62.5%;
}



.e-loadholder {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 240px;
  height: 240px;
  border: 5px solid #1B5F70;
  border-radius: 120px;
  box-sizing: border-box;
}
.e-loadholder:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #00000000;
  transform-origin: center;
  z-index: 0;
}
.e-loadholder:after {
  width: 100px;
  height: 200%;
  -webkit-animation: outerRotate2 30s infinite linear;
  -moz-animation: outerRotate2 30s infinite linear;
  -o-animation: outerRotate2 30s infinite linear;
  animation: outerRotate2 30s infinite linear;
}
.e-loadholder .m-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 200px;
  height: 200px;
  color: #888;
  text-align: center;
  border: 5px solid #2a93ae;
  border-radius: 100px;
  box-sizing: border-box;
  z-index: 20;
  text-transform: uppercase;
}
.e-loadholder .m-loader:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #00000000;
  transform-origin: center;
  z-index: -1;
}
.e-loadholder .m-loader:after {
  width: 100px;
  height: 106%;
  -webkit-animation: outerRotate1 15s infinite linear;
  -moz-animation: outerRotate1 15s infinite linear;
  -o-animation: outerRotate1 15s infinite linear;
  animation: outerRotate1 15s infinite linear;
}
.e-loadholder .m-loader .e-text {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  -webkit-animation: textColour 1s alternate linear infinite;
  -moz-animation: textColour 1s alternate linear infinite;
  -o-animation: textColour 1s alternate linear infinite;
  animation: textColour 1s alternate linear infinite;
  display: block;
  width: 140px;
  height: 140px;
  text-align: center;
  border: 5px solid #3b74d0;
  border-radius: 70px;
  box-sizing: border-box;
  z-index: 20;
}
.e-loadholder .m-loader .e-text:before, .e-loadholder .m-loader .e-text:after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  content: " ";
  display: block;
  background: #00000000;
  transform-origin: center;
  z-index: -1;
}
.e-loadholder .m-loader .e-text:before {
  width: 110%;
  height: 40px;
  -webkit-animation: outerRotate2 3.5s infinite linear;
  -moz-animation: outerRotate2 3.5s infinite linear;
  -o-animation: outerRotate2 3.5s infinite linear;
  animation: outerRotate2 3.5s infinite linear;
}
.e-loadholder .m-loader .e-text:after {
  width: 40px;
  height: 110%;
  -webkit-animation: outerRotate1 8s infinite linear;
  -moz-animation: outerRotate1 8s infinite linear;
  -o-animation: outerRotate1 8s infinite linear;
  animation: outerRotate1 8s infinite linear;
}

#particleCanvas-White {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 100%;
  height: 50%;
  opacity: 0.1;
}

#particleCanvas-Blue {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-51%, -50%);
  -moz-transform: translate(-51%, -50%);
  -ms-transform: translate(-51%, -50%);
  -o-transform: translate(-51%, -50%);
  transform: translate(-51%, -50%);
  width: 300px;
  height: 300px;
}

@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");

.svg1 {
	font-family: "Orbitron", sans-serif;
	width: 100%; height: 100%;
}
svg text {
	animation: stroke 5s infinite alternate;
	stroke-width: 2;
	stroke: #E6DB74;
	font-size: 75px;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
	svg text {
		font-size: 50px;
	}
}
@keyframes stroke {
	0%   {
		fill: rgba(47,95,191,0); stroke: rgb(10, 0, 124);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(47,95,191,0); stroke: #ffffff; }
	80%  {fill: rgba(47,95,191,0); stroke: #ffffff; stroke-width: 3; }
	100% {
		fill: rgba(47,95,191,1); stroke: rgba(230,219,116,0);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}



.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed or use a specific height for the wrapper */
}

/* Additional styles for the SVG text */
.wrapper svg {

  background: linear-gradient(315deg, rgb(19, 19, 19) 3%, rgba(21, 0, 103, 0.863) 38%, rgba(0, 16, 104, 0.842) 68%, rgba(0, 0, 0, 0.795) 98%);
    background-size: 100% 100%;
    background-attachment: fixed;; /* Add background color if necessary */
}

